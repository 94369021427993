import React from 'react'
import './styles'

import Email    from './email.svg'
import Facebook from './facebook.svg'
import Github   from './github.svg'
import Twitter  from './twitter.svg'
import Vimeo    from './vimeo.svg'
import Linkedin from './linkedin.svg'

export default \
class Links extends React.Component
  render: ->
    <div className="Links">
      <a href="mailto:kieran@kieran.ca">
        <Email/>
      </a>
      <a href="https://facebook.com/kieran.huggins">
        <Facebook/>
      </a>
      <a href="https://github.com/kieran">
        <Github/>
      </a>
      <a href="https://twitter.com/kieran">
        <Twitter/>
      </a>
      <a href="https://vimeo.com/khug">
        <Vimeo/>
      </a>
      <a href="https://www.linkedin.com/in/kieranhuggins">
        <Linkedin/>
      </a>
    </div>
