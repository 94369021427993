import React from 'react'
import { Helmet } from "react-helmet"
import Header from '/components/header'
import debounce from 'underscore-es/debounce'

# fixes @reach/router scrolling issue
update_scroll_position = debounce ->
  history.replaceState { history.state..., scrollY }, document.title
, 100

trackGA = ->
  window.gtag? 'config', process.env.GOOGLE_ANALYTICS_ID,
    page_path: location.pathname
    page_title: document.title

export default \
class Layout extends React.Component
  componentDidMount: ->
    addEventListener 'scroll', update_scroll_position
    if y = history.state?.scrollY
      scrollTo 0, y
    else
      scrollTo 0, 0 unless document.querySelector location.hash or null

  componentWillUnmount: ->
    removeEventListener 'scroll', update_scroll_position

  render: ->
    <>
      <Helmet
        defaultTitle="Kieran Huggins"
        titleTemplate="%s - Kieran Huggins"
        onChangeClientState={trackGA}
      >
        <title>{@props.title}</title>
      </Helmet>
      <Header/>
      <main>
        {@props.children}
      </main>
    </>
