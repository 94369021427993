/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsx mdx */
import parseISO from 'date-fns/parseISO'
import ss from './screenshot.jpg'
import uc  from './uc.gif'
export var slug = 'barista';
export var title = 'Barista';
export var date = parseISO('2011-06-11');
export var image = ss;
export var screenshot = ss;
export var url = 'https://kieran.github.io/barista';
export var github = 'https://github.com/kieran/barista';
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  slug
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <img src={image} className="screenshot" />
    <p><a parentName="p" {...{
        "href": "https://kieran.github.io/barista"
      }}>{`Barista`}</a>{` is a URL router & generator, designed to help you decouple routing from your framework's application logic. Its function can be roughly split into two parts: parsing URLs into actionable parameters, and generating URLs from parameters.`}</p>
    <p>{`It enjoyed some popularity as the default router for the `}<a parentName="p" {...{
        "href": "https://github.com/geddy/geddy"
      }}>{`Geddy`}</a>{` web framework in the early days of NodeJS.`}</p>
    <p><em parentName="p">{` ~ More to come... ~ `}</em></p>
    <img src={uc} className="UnDeR cONStRuCtIoN lOl" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
