import React from 'react'

import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import format from 'date-fns/format'

export default \
class TimeAgo extends React.Component
  time_ago_in_words: =>
    formatDistanceToNow @props.date, addSuffix: true
    .replace /almost|about|over/, ''

  date_in_words: =>
    format @props.date, 'PP'

  render: ->
    return null unless @props.date

    <span
      className='TimeAgo'
      title={@date_in_words()}
    >
      {@props.prefix or ''}
      {@time_ago_in_words()}
      {@props.suffix or ''}
    </span>
