import React from 'react'
import './styles'

import cx from 'classnames'
import { Link } from "@reach/router"

import TimeAgo from '/components/time-ago'

import articles from '/articles'

export default \
class Articles extends React.Component
  render: ->
    <div className="Articles">
      {@list_item a for a in articles when a.pinned by -1}
      {@list_item a for a in articles when not a.pinned by -1}
    </div>

  list_item: (a)->
    <Link
      to="/articles/#{a.slug}"
      className={cx 'list-item', pinned: a.pinned}
      key={a.slug}
    >
      <div className="title">{a.title}</div>
      <small className="date">
        <TimeAgo
          prefix="Published "
          date={a.date}
        />
      </small>
    </Link>
