import React from 'react'
import './styles'

import { project } from '/projects'

import Prism from 'prismjs'

import TimeAgo  from '/components/time-ago'
import Layout   from '/components/layout'

import Github   from './github.svg'
import Monitor  from './monitor.svg'
import Clock    from './clock.svg'

export default \
class Project extends React.Component
  componentDidMount: ->
    Prism.highlightAll()

  project: =>
    project @props.slug

  render: ->
    {title, date, url, github, default: Body} = @project()

    <Layout title={title}>
      <article className="Project">
        <header>
          <h1>{title}</h1>
          <span className="info">
            {<a href={url}>
              <Monitor/>
              {url.replace "https://", ''}
            </a> if url?}

            {<a href={github}>
              <Github/>
              {github.replace "https://github.com/", ''}
            </a> if github?}

            <span><Clock/><TimeAgo date={date}/></span>
          </span>
        </header>
        <div className="body">
          <Body/>
        </div>
      </article>
    </Layout>
