import sortBy     from 'underscore-es/sortBy'
import findWhere  from 'underscore-es/findWhere'

import projects from './*/index.mdx'

# chronological order by date
export default sortBy Object.values(projects), 'date'

export project = (slug)->
  findWhere Object.values(projects), {slug}
