import sortBy     from 'underscore-es/sortBy'
import findWhere  from 'underscore-es/findWhere'

import articles from './*/index.mdx'

# chronological order by date
export default sortBy Object.values(articles), 'date'

export article = (slug)->
  findWhere Object.values(articles), {slug}
