/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsx mdx */
import parseISO from 'date-fns/parseISO'
export var slug = 'on-time';
export var title = 'On Time';
export var pinned = true;
export var date = parseISO('2019-11-01');
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  slug
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus a orci non tortor condimentum commodo placerat sed nunc. Nulla tincidunt est sit amet faucibus ultrices. Fusce pretium est diam, ac consectetur metus consectetur nec. In scelerisque aliquet metus sit amet tempor. Aenean aliquam porta velit quis luctus. Aliquam erat volutpat. Donec finibus nisl at magna elementum, luctus molestie neque venenatis. Proin dapibus turpis enim, sed tempor mauris maximus sed. In porta efficitur feugiat. Suspendisse hendrerit enim at felis suscipit tincidunt. Nam eleifend purus sit amet purus iaculis fringilla. Suspendisse rutrum eros vitae tellus dapibus blandit.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
