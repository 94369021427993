import React from 'react'
import './styles'

import { article } from '/articles'

import Layout   from '/components/layout'
import TimeAgo  from '/components/time-ago'

export default \
class Article extends React.Component
  article: =>
    article @props.slug

  render: ->
    { title, date, slug, default: Body } = @article()

    <Layout title={title}>
      <article className="Article">
        <header>
          <h1>{title}</h1>
          <TimeAgo date={date}/>
        </header>
        <div className="body">
          <Body/>
        </div>
      </article>
    </Layout>
