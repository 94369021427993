/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsx mdx */
import parseISO from 'date-fns/parseISO'
import ss from './screenshot.png'
import Video from '/components/video'
import uc  from './uc.gif'
export var slug = 'myttc';
export var title = 'MyTTC';
export var date = parseISO('2008-06-01');
export var image = ss;
export var screenshot = ss;
export var url = 'https://myttc.ca';
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  slug
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <img src={image} className="screenshot" />
    <p><a parentName="p" {...{
        "href": "https://myttc.ca"
      }}>{`MyTTC`}</a>{` was a public transit trip planner for Toronto.`}</p>
    <p>{`Conceived at Transit Camp in 2007 as a replacement for the outdated (at the time) TTC website, `}<a parentName="p" {...{
        "href": "https://kevin.branigan.ca"
      }}>{`Kevin Branigan`}</a>{` and I went to work on an increasingly complex project to generate, use, and share transit data in new and interesting ways.`}</p>
    <p><a parentName="p" {...{
        "href": "https://torontoist.com/2008/07/ttcca_meet_myttcca/"
      }}>{`The project launched`}</a>{` in the summer of 2008, just in time for `}<a parentName="p" {...{
        "href": "http://unspace.ca/rubyfringe/"
      }}>{`RubyFringe`}</a>{`, a local tech conference. It was also `}<a parentName="p" {...{
        "href": "https://torontoist.com/2010/02/ttcs_trip_planner_vs_myttcca/"
      }}>{`favourably compared`}</a>{` to an official trip planner, subsequently purchased by the TTC in 2010.`}</p>
    <p>{`To date, it has served millions of directions to transit riders in the city.`}</p>
    <p>{`Alas, it is now obsolete in the wake of Google Transit (which is excellent, you should use it).`}</p>
    <h2>{`TTC vehicle simulation`}</h2>
    <p>{`As a way to visually inspect our generated stoptime data, we plotted an entire day's worth of
vehicle positions using `}<inlineCode parentName="p">{`R`}</inlineCode>{`.`}</p>
    <Video src="https://player.vimeo.com/video/1865789" mdxType="Video" />
    <h2>{`Trip planner overview`}</h2>
    <p>{`Another tool we built for visual debugging, this time using `}<inlineCode parentName="p">{`OpenGL`}</inlineCode>{`, to display
a "live view" of the trip planner exploring potential options.`}</p>
    <Video src="https://player.vimeo.com/video/9248101" mdxType="Video" />
    <p><em parentName="p">{` ~ More to come... ~ `}</em></p>
    <img src={uc} className="UnDeR cONStRuCtIoN lOl" />
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
