import React from 'react'
import './styles'

import { Link } from "@reach/router"

import Links  from '/components/links'
import avatar from './avatar.jpg'

export default \
class Header extends React.Component
  render: ->
    <header className="Header">
      <div className="about">
        <Link to="/"><img src={avatar} alt="Kieran Huggins" /></Link>
        <p>
          Hi, I’m <strong>Kieran Huggins</strong>
        </p>
        <p>
          Director, R<small>&</small>D at
          {' '}<a href="https://universe.com">Universe</a>
        </p>
        <Links/>
      </div>
    </header>
