/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsx mdx */
import parseISO from 'date-fns/parseISO'
export var slug = 'on-work';
export var title = 'On Work';
export var date = parseISO('2019-10-20');
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  slug
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Morbi sit amet pharetra nibh. Nulla sed porta lacus. Pellentesque eu nisl sollicitudin, maximus neque nec, fermentum quam. In ut fermentum elit, non venenatis dolor. Vivamus sem dolor, porttitor at blandit sit amet, tristique ut orci. Phasellus imperdiet, libero vel venenatis malesuada, est ex porttitor ipsum, eget suscipit justo est eget metus. Nam dictum nibh augue. Proin quis mauris lacus. Etiam a ipsum eget diam vulputate accumsan. Nullam eu malesuada sapien. Suspendisse sed mattis tellus. In maximus maximus sapien, id pellentesque est tristique tincidunt. Donec lorem arcu, lobortis at tristique id, egestas ac nisi. Etiam massa lectus, egestas condimentum sem a, viverra aliquam odio. Ut consectetur nisl et euismod gravida.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
