import React from 'react'
import './styles'

import { Router, Link } from "@reach/router"

import Header from '/components/header'
import Layout from '/components/layout'

import Articles  from '/routes/articles'
import Article   from '/routes/articles/show'
import Projects  from '/routes/projects'
import Project   from '/routes/projects/show'

import GetInTouch from './get-in-touch'

import welcome  from './welcome.gif'

export default \
class Application extends React.Component
  render: ->
    <Router primary={false} className='Application' id="application">
      <Articles path="/articles" />
      <Article path="/articles/:slug" />
      <Projects path="/projects" />
      <Project path="/projects/:slug"/>
      <Lol path="/"/>
    </Router>

class Lol extends React.Component
  render: ->
    <Layout>
      {@welcome() if Math.random() < 0.1}
      {null and @articles()}
      {@projects()}
    </Layout>

  welcome: ->
    <section className="welcome">
      <img src={welcome} />
    </section>


  articles: ->
    <section className="articles">
      <h1>Articles</h1>
      <Articles/>
    </section>

  projects: ->
    <section className="projects">
      <h1>Projects</h1>
      <Projects/>
    </section>
