import React from 'react'
import './styles'

import cx from 'classnames'

import { Link } from "@reach/router"

import projects from '/projects'

import formatDistanceToNow from 'date-fns/formatDistanceToNow'

export default \
class Project extends React.Component
  render: ->
    <div className="Projects">
      {@list_item a for a in projects when a.pinned by -1}
      {@list_item a for a in projects when not a.pinned by -1}
    </div>

  list_item: (a)->
    <Link
      to="/projects/#{a.slug}"
      className={cx 'list-item', pinned: a.pinned}
      key={a.slug}
    >
      <figure>
        <img src={a.screenshot or a.image} className={cx screenshot: a.screenshot?}/>
        <figcaption>{a.title}</figcaption>
      </figure>
    </Link>
