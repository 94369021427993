import React from 'react'
import PropTypes from 'prop-types'
import './styles'

export default \
class Video extends React.Component
  @defaultProps:
    width: 640
    height: 360

  aspect: ->
    @props.height / @props.width

  render: ->
    return null unless @props.src
    <div
      className="Video"
      style={{paddingTop: "#{@aspect()* 100}%"}}
    >
      <iframe
        src={@props.src}
        width={@props.width}
        height={@props.height}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      ></iframe>
    </div>
