/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsx mdx */
import parseISO from 'date-fns/parseISO'
import ss from './screenshot.png'
import ga from './ga-*.png'
export var slug = 'votewell';
export var title = 'VoteWell';
export var date = parseISO('2018-06-06');
export var image = ss;
export var screenshot = ss;
export var url = 'https://votewell.ca';
export var github = 'https://github.com/kieran/votewell';
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  slug
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <img src={image} className="screenshot" />
    <p>{`Votewell is a strategic voting tool for elections where the left vote is split amongst several parties. It's published for elections in `}<a parentName="p" {...{
        "href": "https://votewell.ca"
      }}>{`Canada`}</a>{`, and recently also for the `}<a parentName="p" {...{
        "href": "https://votewell.co.uk"
      }}>{`2019 UK General Election`}</a></p>
    <h2>{`The problem`}</h2>
    <p>{`Canada, for example, has several national parties: one conservative (right-leaning) party, and three progressive (leftist) parties. It's very common for the left vote to be fragmented among the three, resulting in conservative wins despite not having a majority of the votes.`}</p>
    <p>{`The problem is a well-understood consequence of `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/First-past-the-post_voting"
      }}>{`first-past-the-post voting`}</a>{`, which many countries (including Canada and the UK) still use today. There are `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=r9rGX91rq5I"
      }}>{`excellent and entertaining illustrations`}</a>{` of this problem on the Internet already, so I won't attempt to cover it here.`}</p>
    <h3>{`An information problem`}</h3>
    <p>{`The whole point of voting is for people to `}<em parentName="p">{`communicate their preferences`}</em>{` to the government, so they may be fairly represented in parliament.`}</p>
    <p>{`The issue with the current system is that we're `}<em parentName="p">{`losing important information`}</em>{` when we cast our vote. Imagine the following ballot:`}</p>
    <pre><code parentName="pre" {...{}}>{`[x] Party A  (Left)
[ ] Party B  (Left)
[ ] Party C  (Left)
[ ] Party D  (Right)
`}</code></pre>
    <p>{`We can assume one of two things from this ballot:`}</p>
    <ul>
      <li parentName="ul">{`The voter either wants `}<inlineCode parentName="li">{`Party A`}</inlineCode>{` to represent them, or`}</li>
      <li parentName="ul">{`The voter does `}<em parentName="li">{`not`}</em>{` want `}<em parentName="li">{`one or more`}</em>{` parties `}<inlineCode parentName="li">{`B`}</inlineCode>{`, `}<inlineCode parentName="li">{`C`}</inlineCode>{`, or `}<inlineCode parentName="li">{`D`}</inlineCode>{` in power`}</li>
    </ul>
    <p>{`Strictly from the perspective of encoding information, we can do better.`}</p>
    <p>{`In this scenario, it's typical for the `}<em parentName="p">{`intent`}</em>{` of the voter to be closer to the following:`}</p>
    <ul>
      <li parentName="ul">{`I would prefer `}<inlineCode parentName="li">{`Party A`}</inlineCode>{` to win`}</li>
      <li parentName="ul">{`Either party `}<inlineCode parentName="li">{`B`}</inlineCode>{` or `}<inlineCode parentName="li">{`C`}</inlineCode>{` would be ok`}</li>
      <li parentName="ul">{`I absolutely `}<em parentName="li">{`do not want`}</em>{` `}<inlineCode parentName="li">{`Party D`}</inlineCode>{` in power`}</li>
    </ul>
    <p>{`One way to better communicate these preferences is by using a voting system that incorporates a ranked ballot.`}</p>
    <pre><code parentName="pre" {...{}}>{`[1] Party A  (L)
[3] Party B  (L)
[2] Party C  (L)
[ ] Party D  (R)
`}</code></pre>
    <p>{`Here we have a `}<em parentName="p">{`lot more information`}</em>{` encoded in the ballot. We can now know:`}</p>
    <ul>
      <li parentName="ul">{`The voter's preferred choice (`}<inlineCode parentName="li">{`A`}</inlineCode>{`)`}</li>
      <li parentName="ul">{`The voter's alternative choices (in order, `}<inlineCode parentName="li">{`C`}</inlineCode>{` and `}<inlineCode parentName="li">{`B`}</inlineCode>{`)`}</li>
      <li parentName="ul">{`Any party the voter `}<em parentName="li">{`does not support`}</em>{` (`}<inlineCode parentName="li">{`D`}</inlineCode>{`)`}</li>
    </ul>
    <h3>{`Strategic voting`}</h3>
    <p>{`Strategic voting is essentially a manual version of `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Instant-runoff_voting"
      }}>{`Instant-runoff voting`}</a>{` - where you transfer your vote to `}<em parentName="p">{`any acceptable party`}</em>{` who's likely to win.`}</p>
    {
      /*
      In the above scenario, say `Party C` is the leading choice aboung the leftist parties. If all leftist voters transferred their votes to `C`, then would easily beat `D`, who have minority support.
      */
    }
    <p>{`It's very common to vote strategically in Canada, but the problem is you need to know who‘s likely to win in your riding `}<em parentName="p">{`before you vote`}</em>{`.`}</p>
    <h2>{`Enter VoteWell`}</h2>
    <p>{`The method for determining the leading leftist party is fairly straightforward, but involves a lot of manual work. I wrote VoteWell to automate these steps:`}</p>
    <ol>
      <li parentName="ol">{`Determine what riding you‘re in`}</li>
      <li parentName="ol">{`Collect polling data`}</li>
      <li parentName="ol">{`Predict the likely election results per riding`}</li>
      <li parentName="ol">{`Figure out if strategic voting is necessary in your riding`}</li>
      <li parentName="ol">{`Determine which party to vote for, if necessary`}</li>
    </ol>
    <h3>{`Polling data`}</h3>
    <p>{`There are
`}<a parentName="p" {...{
        "href": "https://calculatedpolitics.ca"
      }}>{`lots`}</a>{`
`}<a parentName="p" {...{
        "href": "https://338canada.com"
      }}>{`of`}</a>{`
`}<a parentName="p" {...{
        "href": "http://www.tooclosetocall.ca"
      }}>{`election`}</a>{`
`}<a parentName="p" {...{
        "href": "https://leantossup.ca"
      }}>{`prediction`}</a>{`
`}<a parentName="p" {...{
        "href": "https://www.electionprediction.org/2019_fed/p_35on.php"
      }}>{`blogs`}</a>{`
in Canada, each with their own strengths. They all use their own blend of statistics and/or machine learning to weight and cast polling data in different ridings.`}</p>
    <p>{`After surveying the lot, I decided to use
`}<a parentName="p" {...{
        "href": "https://calculatedpolitics.ca"
      }}>{`Calculated Politics`}</a>{`
as the source of my prediction data. They had what seemed like a good methodology, and they published per-riding prediction data.`}</p>
    <p>{`I wrote a
`}<a parentName="p" {...{
        "href": "https://github.com/kieran/votewell/blob/master/elections/ca/update_polls.coffee#L18-L28"
      }}>{`quick regex`}</a>{`
to parse the prediction data, distilling it into a single `}<inlineCode parentName="p">{`JSON`}</inlineCode>{` file.`}</p>
    <h3>{`Displaying the data`}</h3>
    <p>{`React was a natural fit for this project. By moving all the data, logic, and UI into the browser, I‘d be able to get by with static hosting. Any worries I had about potential traffic spikes would be essentially moot, since CDNs are designed and built for heavy static load. Although I did end up introducing a server component later, the decision to go static/CDN continued to pay huge dividends.`}</p>
    {
      /*
      I use (and love)
      [parcel](https://parceljs.org)
      for all my react projects.
      */
    }
    <p>{`In the most basic sense, the site allows you to select your riding, consults the data we previously collected, `}<a parentName="p" {...{
        "href": "https://github.com/kieran/votewell/blob/master/routes/application/index.coffee#L63-L75"
      }}>{`computes your best option`}</a>{`, then tells you who you that option is. An extremely high-fidelity mockup follows:`}</p>
    <blockquote className="ux-sketch">
  <strong>A vote in </strong>
  <select>
    <option>Select your riding...</option>
    <option>Riding One</option>
    <option>Riding Two</option>
    <option>...</option>
  </select>
  <strong> is a vote for</strong>
      <pre><code parentName="pre" {...{}}>{`Party B!
--------
                        #####
        #####           #####
        #####   #####   #####
#####   #####   #####   #####
  A       B       C       D
`}</code></pre>
    </blockquote>
    <p>{`Add to that some extremely basic `}<inlineCode parentName="p">{`sass`}</inlineCode>{` styling and the project has reached MVP status. That means it‘s time to...`}</p>
    <h3>{`Ship it™`}</h3>
    <p>{`We're spoiled for choice for hosting static sites lately, but nothing quite matches the convenience of `}<a parentName="p" {...{
        "href": "https://www.netlify.com"
      }}>{`Netlify`}</a>{`.`}</p>
    <p>{`As an added bonus, Netlify's Github integration & automated build process fit my needs perfectly. By default, they'll take any push to your master branch on GitHub, run `}<inlineCode parentName="p">{`npm run build`}</inlineCode>{`, and deploy the resulting `}<inlineCode parentName="p">{`./dist`}</inlineCode>{` folder.`}</p>
    <p>{`After creating the site on Netlify, adding a `}<inlineCode parentName="p">{`parcel build`}</inlineCode>{` command to my `}<inlineCode parentName="p">{`package.json`}</inlineCode>{` was all it took to get to get production deploys working:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "name": "votewell",
  ...
  "scripts": {
    "start": "npx parcel serve ./index.html",
    "build": "npx parcel build ./index.html"
  },
  "dependencies": { ... }
}
`}</code></pre>
    <p>{`You can run `}<inlineCode parentName="p">{`npm run build`}</inlineCode>{` yourself locally to see the output.`}</p>
    <p>{`Really, any static host would work at this point, but I can't think of a reason not to use Netlify. This is not a paid placement, but I'm aware it sounds like one 🤣.`}</p>
    <h2><inlineCode parentName="h2">{`loop do { ... }`}</inlineCode></h2>
    <p>{`Now that VoteWell is "in the wild", the bulk of the work can begin 🛠️. Here are some iterations, in no particular order.`}</p>
    <h3>{`Geolocation`}</h3>
    {
      /* <h3 id="geolocation">Geolocation</h3> */
    }
    <p>{`Almost nobody knows what riding they‘re in. Fortunately, all you need to figure that out is their location and the right map. And modern browsers can tell you their location!`}</p>
    <p>{`Ideally, I wanted a way to default the riding selector to `}<em parentName="p">{`where the user was`}</em>{`. That way, if they wanted to look at the results for other ridings, they could just select it by name. Also, if the geolocation failed (or was denied) everything still worked, just not as well. I like to think of UI enhancements as escalators - if they fail they should still work as stairs.`}</p>
    <p>{`I found an `}<inlineCode parentName="p">{`ESRI Shapefile`}</inlineCode>{` of the federal electoral districts via
`}<a parentName="p" {...{
        "href": "https://open.canada.ca/data/en/dataset/737be5ea-27cf-48a3-91d6-e835f11834b0"
      }}>{`Open Data Canada`}</a>{`.
Now all I had to do was figure out which shape (riding) contained a provided `}<inlineCode parentName="p">{`lat/lng`}</inlineCode>{`.`}</p>
    <p>{`Hoping to keep this work in the browser, I looked around for a JS solution and found `}<a parentName="p" {...{
        "href": "https://github.com/Turfjs/turf/tree/master/packages/turf-tag"
      }}>{`Turf`}</a>{`. Basically, it will load a bunch of `}<inlineCode parentName="p">{`geoJSON`}</inlineCode>{` shapes into memory, and allows you to find the shapes that contain any given point.`}</p>
    <p>{`First hurdle: Turf expects `}<inlineCode parentName="p">{`geoJSON`}</inlineCode>{` data, and I have an `}<inlineCode parentName="p">{`ESRI Shapefile`}</inlineCode>{`. Without getting too detailed, this is not a trivial conversion. Fortunately, a tool exists to make the necessary conversion. I made a note on how to install / run the tool `}<a parentName="p" {...{
        "href": "https://github.com/kieran/votewell#converting-boundary-shapefiles-to-geojson"
      }}>{`in the README`}</a>{`.`}</p>
    <p>{`Even after simplifying the shape data, it still takes up a whopping 1.3 MB, or `}{`~`}{`500kb gzipped. Certainly possible, but less than ideal when all you really care about is a `}{`~`}{`20 byte riding name.`}</p>
    <aside className="factoid">
      <p>{`  Fun fact: The original version of VoteWell was limited to Ontario, and shipped the entire `}<inlineCode parentName="p">{`geoJSON`}</inlineCode>{` file to the browser.`}</p>
      <p>{`  It worked!`}</p>
    </aside>
    <p>{`I moved the `}<inlineCode parentName="p">{`lat/lng`}</inlineCode>{` -> `}<inlineCode parentName="p">{`riding`}</inlineCode>{` resolution into an API call, and deployed it to AWS Lambda via `}<a parentName="p" {...{
        "href": "https://apex.sh/docs/up/"
      }}>{`up`}</a>{`.
Lambda is great because it seamlessly auto-scales from 0 to... a lot, then back down to 0 again.
The downside to lambda is that it tends to get a little cost-inefficient under high load.`}</p>
    <p>{`Fearing high traffic, I moved the service to a tiny lightsail instance instead (the $3.50/mo one). It was a nice predictable Ubuntu environment running nodemon. It never broke a sweat, hovering around 1% load. Clearly overkill, and being a VPS, it's always running.`}</p>
    <p>{`What I `}<em parentName="p">{`really`}</em>{` wanted was a hybrid of the two - a VPS that auto-scaled up, then down to 0 for the off-season. I ended up finding that in `}<a parentName="p" {...{
        "href": "https://cloud.google.com/run/"
      }}>{`Google Cloud Run`}</a>{`.`}</p>
    <p>{`It basically works like lambda, but instead of a single function it exposes a running Docker container. You're charged in typical 100ms windows (with a generous free tier), and it pauses the instance after every request. One thing that differentiates it, though, is that it can handle `}<em parentName="p">{`concurrent requests`}</em>{` per instance. If you have several requests that all complete within the same 100ms window, you're only billed once (while the instance is live).`}</p>
    <p>{`Of the three, the UX for `}<inlineCode parentName="p">{`up`}</inlineCode>{` was certainly the best. GCP is much rougher around the edges, but the usage fit was too hard to ignore.`}</p>
    <p>{`Another small change I made was moving the `}<inlineCode parentName="p">{`lat/lng`}</inlineCode>{` -> `}<inlineCode parentName="p">{`riding`}</inlineCode>{` resolution itself `}<a parentName="p" {...{
        "href": "https://github.com/kieran/votewell/blob/master/server/mongo.coffee#L12-L18"
      }}>{`into mongo`}</a>{`. Shedding the constraints of working in a browser environment opened up some great options wrt: `}<inlineCode parentName="p">{`geoJSON`}</inlineCode>{`, and mongo has some really fantastic `}<inlineCode parentName="p">{`geoJSON`}</inlineCode>{` index support.
Lookups became `}<em parentName="p">{`at least an order of magnitude faster`}</em>{`, which is nice.`}</p>
    <p>{`The biggest hurdle in moving to Cloud Run was probably getting the mongo service to run inside the Docker container. Arguably, running both services inside one container is a distinct anti-pattern, but it fits this use case. I ended up
`}<a parentName="p" {...{
        "href": "https://github.com/kieran/votewell/blob/master/Makefile#L66-L73"
      }}><del parentName="a">{`ab`}</del>{`using job control`}</a>{`
in `}<inlineCode parentName="p">{`bash`}</inlineCode>{` to this effect.`}</p>
    <h3>{`Netlify: a caching proxy?`}</h3>
    <p>{`I discovered a really great feature of Netlify during all this: it can act as a `}<em parentName="p">{`caching proxy`}</em>{`! If you specify `}<a parentName="p" {...{
        "href": "https://github.com/kieran/votewell/blob/master/netlify.toml#L18-L21"
      }}>{`a redirect rule`}</a>{` in your `}<inlineCode parentName="p">{`netlify.toml`}</inlineCode>{` with a code of `}<inlineCode parentName="p">{`200`}</inlineCode>{`, Netlify will proxy the request for you. What's more, if you set `}<inlineCode parentName="p">{`Cache-Control`}</inlineCode>{` headers it will cache the results on the same CDN it uses to host your static files. This single feature ended up intercepting about 80% of my geolocation requests, cutting my server load by a factor of 5.`}</p>
    <blockquote className="ux-sketch">
      <pre><code parentName="pre" {...{}}>{`/api/1  ⟶ [Netlify] ⟶ [Server]
        ⟵ [Netlify] ⟵

/api/1  ⟶ [Netlify]
        ⟵ (cached!)

/api/2  ⟶ [Netlify] ⟶ [Server]
        ⟵ [Netlify] ⟵
`}</code></pre>
    </blockquote>
    <p>{`Another nice benefit is that you don't need to bother with the `}<inlineCode parentName="p">{`CORS`}</inlineCode>{` dance.`}</p>
    <h3>{`Design`}</h3>
    <p>{`The effect good design has on a product is difficult for me to articulate, but it's absolutely a force multiplier.`}</p>
    <p>{`I was super fortunate to catch the attention of my friend, the `}<em parentName="p">{`extremely talented`}</em>{` `}<a parentName="p" {...{
        "href": "http://arthurchayka.com"
      }}>{`Arthur Chayka`}</a>{`, who took it upon himself to lend me his design direction and expertise.`}</p>
    <p>{`I'm sure VoteWell owes `}<em parentName="p">{`a lot`}</em>{` of its traction to Arthur's design chops.`}</p>
    <h3>{`Measure all the things!`}</h3>
    <p>{`I‘m admittedly a bit of an Analytics Noob, but I always make sure at least the most basic Google Analytics agent is present.`}</p>
    <div className="analytics">
  <figure>
    <img className="screenshot" src={ga.sessions} />
    <figcaption>Interest peaks on election day</figcaption>
  </figure>
  <figure>
    <img className="screenshot" src={ga.media} />
    <figcaption>Overwhelmingly mobile</figcaption>
  </figure>
  <figure>
    <img className="screenshot" src={ga.language} />
    <figcaption>~1% French</figcaption>
  </figure>
  <figure>
    <img className="screenshot" src={ga.social} />
    <figcaption>Facebook dominant</figcaption>
  </figure>
    </div>
    <p>{`Lots of interesting information can be gleaned from the default analytics.`}</p>
    <p>{`For example: I delayed launching the site until I had French translations, assuming about 20% of my traffic would be French (which is in line with census data). In retrospect, I should have launched right away, then prioritized according to the actual data.`}</p>
    <p>{`I'm always surprised by just how dominant mobile traffic is. I'm not sure why I have this expectation bias towards desktop, maybe because I develop on it? I need to get with the early 2000's and start designing mobile first already.`}</p>
    <h3>{`Error tracking`}</h3>
    <p>{`Another thing to file in the "I wish I had always just done this" folder is error / exception tracking.
`}<a parentName="p" {...{
        "href": "https://sentry.io"
      }}>{`Sentry`}</a>{` is the only one I've had a decent amount of experience with, and their free tier is certainly enough to get you going.`}</p>
    <p>{`You'll get a bit of noise from misbehaving browser extensions, but catching those early head-smacking errors is absolutely worth it.`}</p>
    <p>{`Bonus points for tagging the user context (if applicable) so you can follow up personally with news of a fix.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
