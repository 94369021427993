/* @jsx mdx */
import React from 'react';
import { mdx } from '@mdx-js/react'
/* @jsx mdx */
import parseISO from 'date-fns/parseISO'
export var slug = 'on-money';
export var title = 'On Money';
export var date = parseISO('2019-11-10');
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
  return <div {...props}/>
};

const layoutProps = {
  slug
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Sed et venenatis augue. Nullam viverra diam a nibh posuere, eu venenatis erat molestie. Duis consectetur ornare volutpat. Nam interdum ex eu nisl finibus, eu laoreet sem aliquet. Vivamus mollis erat in magna auctor sollicitudin. Suspendisse posuere consequat justo, eget blandit mi feugiat nec. Ut dolor dui, eleifend a est lobortis, tristique porttitor orci. Morbi consectetur lectus velit, quis tincidunt urna accumsan vel. Integer non lobortis magna. Nulla porta mi justo, non posuere leo bibendum eu. Donec bibendum malesuada urna. Nunc ac scelerisque arcu, sit amet vestibulum nisl. Nullam sed nibh urna.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
